<template>
  <section class="daily-preview" v-loading.fullscreen.lock="loading">
    <section class="daily-preview-top">
      <div class="daily-title">
        <span>{{ dataList.templateName }}</span>
      </div>
      <div class="daily-date">
        <span>{{ dataList.createDate }}</span>
        <!-- <el-button type="primary" size="mini">发送</el-button> -->
        <el-button @click="downloadReport" size="mini">生成日报并下载</el-button>
      </div>
    </section>
    <section class="daily-preview-content">
      <div class="daily-content">
        <span>{{dataList.allTotal}}</span>
        <div class="daily-data-content">
          <ul v-for="theme in dataList.themeList" :key="theme.id">
            <li style="margin-top: 30px;margin-bottom: 20px;font-size: 18px"><b>{{theme.themeName}} ({{theme.themeCount}})</b></li>
            <li>
              <ul v-for="news in theme.newsList" :key="news.SN">
                <li style="font-size: 15px">{{news.Title}}</li>
                <el-divider></el-divider>
              </ul>
            </li>
            <li>
              <ul v-for="newsGroup in theme.groupList" :key="newsGroup.id">
                <li><b>{{newsGroup.groupName}} </b>({{newsGroup.groupCount}})</li>
                <el-divider></el-divider>
                <li>
                  <ul v-for="childNews in newsGroup.childList" :key="childNews.id">
                    <li  style="font-size: 15px">{{childNews.childTitle}}</li>
                    <el-divider></el-divider>
                  </ul>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
    </section>
  </section>
</template>

<script>
import { getToken } from '@/utils/auth'
import { apiBaseURL } from '@/utils/request'
import { previewDaily, creatDaily } from '@/api/daily/data'
export default {
  data () {
    return {
      dailyData: '',
      dataList: [],
      loading: false,
      count: 0
    }
  },
  methods: {
    async preview () {
      this.loading = true
      try {
        const res = await previewDaily(this.dailyData)
        if (res.code === 0) {
          this.dataList = res.data
          this.loading = false
          this.count = res.data.allTotal
        } else {
          this.$message.error(res.msg)
        }
      } catch (e) {
        this.$message.error('服务器内部异常')
      }
    },
    async downloadReport () {
      this.$message.success('日报正在生成，请耐心等待')
      if (this.count > 5000) {
        this.$notify({
          title: '警告',
          message: '导出单个日报内容已达上限，超出内容将不会被导出',
          type: 'warning'
        })
      }
      const res = await creatDaily(this.dailyData)
      if (res.code === 0) {
        try {
          let token = getToken()
          let down = document.createElement('a')
          down.href = apiBaseURL + '/report/downloadDaily?id=' + res.data.id + '&token=' + token
          down.click()
        } catch (error) {
          this.$message.error('服务器内部异常')
        }
      } else {
        this.$message.error(res.msg)
      }
    }
  },
  mounted () {
    this.dailyData = JSON.parse(this.$route.query.data)
    this.preview()
  }
}
</script>

<style lang="scss">
.daily-preview {
  width: 100%;
  height: 100%;
  .daily-preview-top{
    position: relative;
    left: 10px;
    border: 1px #eceaea solid;
    background: #ffffff;
    .daily-title{
      text-align: center;
      font-size: 25px;
      margin-top: 30px;
    }
    .daily-date{
      text-align: right;
      margin-top: 20px;
      margin-bottom: 20px;
      .el-button--mini{
        margin-right: 20px;
        margin-left:20px;
      }
    }
  }
  .daily-preview-content{
    position: relative;
    left: 10px;
    border: 1px #eceaea solid;
    background: #ffffff;
    .daily-content{
      margin: 20px 40px 40px 20px;
      .el-divider--horizontal{
        height: 0.8px;
        margin: 10px 0px;
      }
    }
  }
}
</style>
